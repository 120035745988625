.contact__container {
  position: relative;
  z-index: 1;
  margin: 15rem auto;
  margin-bottom: 3rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }

  .contact__intro {
    margin-bottom: 0rem;
    text-align: center;
    font-size: 4.7rem;
    font-family: "Pathway Gothic One", sans-serif;

    color: #d1d1d1;

    h1 {
      font-size: 4.7rem;
      font-family: "Pathway Gothic One", sans-serif;
    }

    div {
      width: 20%;
      height: 3px;
      border-radius: 5px;
      align-self: center;
      background-color: #ee5134;
      margin: 0 auto;
    }

    h5 {
      font-family: "Poiret One", cursive;
      font-size: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  .contact__details__container {
    margin-top: 10rem;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;

    @media (max-width: 968px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .social {
      text-align: start;
      display: flex;
      flex-direction: column;
      padding: 3rem;

      @media (max-width: 968px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        word-wrap: break-word;
      }

      h5 {
        font-family: "Poiret One", cursive;
        font-size: 1.5rem;
        color: #ee5134;

        &:last-of-type {
          margin-top: 3rem;
        }
      }

      a {
        font-family: "Poiret One", cursive;
        display: block;
        font-size: 4rem;
        color: #d1d1d1;
        word-wrap: break-word;

        @media (max-width: 968px) {
          font-size: 3rem;
        }

        @media (max-width: 668px) {
          font-size: 2rem;
        }
      }
    }

    .personal__phone,
    .personal__address,
    .email {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      @media (max-width: 768px) {
        text-align: center;
      }

      .vertical__Line {
        width: 1px;
        height: 4rem;
        background-color: #d1d1d1;

        @media (max-width: 768px) {
          display: none;
        }
      }

      .details {
        width: 80%;
        margin-right: 2rem;

        @media (max-width: 768px) {
          margin: auto;
          width: 100%;
        }
      }
    }

    .personal h5 {
      font-family: "Pathway Gothic One", sans-serif;
      color: #d1d1d1;
      font-size: 1.5rem;
    }

    .personal {
      font-family: "Poiret One", cursive;
      text-align: end;
      font-size: 1.5rem;
      color: #d1d1d1;
      word-wrap: break-word;
    }

    i {
      color: #ee5134;
      padding: 1rem;
      width: 56px;
      height: 56px;
      word-wrap: break-word;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .copywrights {
    margin-top: 10rem;
    font-family: "Poiret One", cursive;
    color: #d1d1d1;
    text-align: center;
  }
}
