.projects__container {
  position: relative;
  z-index: 1;
  font-size: 4.7rem;
  font-family: "Pathway Gothic One", sans-serif;
  color: #d1d1d1;
  text-align: center;
  margin-top: 15rem;
  margin-bottom: 15rem;
  overflow: hidden;
}
