@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #23252a;
  overflow: hidden;
}

.background span {
  width: 1vmin;
  height: 1vmin;
  border-radius: 1vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 30;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.background span:nth-child(0) {
  color: #ee5134;
  top: 89%;
  left: 98%;
  animation-duration: 175s;
  animation-delay: -92s;
  transform-origin: -14vw 15vh;
  box-shadow: -2vmin 0 0.7957527454619524vmin currentColor;
}
.background span:nth-child(1) {
  color: #ee5134;
  top: 61%;
  left: 76%;
  animation-duration: 32s;
  animation-delay: -176s;
  transform-origin: -13vw 18vh;
  box-shadow: -2vmin 0 0.726216376870203vmin currentColor;
}
.background span:nth-child(2) {
  color: #ee5134;
  top: 86%;
  left: 35%;
  animation-duration: 204s;
  animation-delay: -53s;
  transform-origin: -2vw 4vh;
  box-shadow: 2vmin 0 1.1803345541689119vmin currentColor;
}
.background span:nth-child(3) {
  color: #ee5134;
  top: 34%;
  left: 88%;
  animation-duration: 158s;
  animation-delay: -20s;
  transform-origin: -1vw 2vh;
  box-shadow: -2vmin 0 0.726495318808512vmin currentColor;
}
.background span:nth-child(4) {
  color: #ee5134;
  top: 97%;
  left: 30%;
  animation-duration: 13s;
  animation-delay: -104s;
  transform-origin: 3vw 18vh;
  box-shadow: -2vmin 0 0.3632165855473497vmin currentColor;
}
.background span:nth-child(5) {
  color: #ee5134;
  top: 67%;
  left: 6%;
  animation-duration: 148s;
  animation-delay: -129s;
  transform-origin: -7vw -4vh;
  box-shadow: 2vmin 0 0.5980416422974573vmin currentColor;
}
.background span:nth-child(6) {
  color: #ee5134;
  top: 93%;
  left: 40%;
  animation-duration: 79s;
  animation-delay: -166s;
  transform-origin: 15vw -6vh;
  box-shadow: 2vmin 0 0.8718145698297819vmin currentColor;
}
.background span:nth-child(7) {
  color: #d1d1d1;
  top: 60%;
  left: 63%;
  animation-duration: 169s;
  animation-delay: -115s;
  transform-origin: -17vw -2vh;
  box-shadow: 2vmin 0 0.5619861185463876vmin currentColor;
}
.background span:nth-child(8) {
  color: #d1d1d1;
  top: 84%;
  left: 91%;
  animation-duration: 86s;
  animation-delay: -31s;
  transform-origin: -4vw 20vh;
  box-shadow: -2vmin 0 1.1442875067939497vmin currentColor;
}
.background span:nth-child(9) {
  color: #ee5134;
  top: 93%;
  left: 30%;
  animation-duration: 154s;
  animation-delay: -157s;
  transform-origin: 18vw -21vh;
  box-shadow: -2vmin 0 1.2088448077370155vmin currentColor;
}
.background span:nth-child(10) {
  color: #ee5134;
  top: 86%;
  left: 94%;
  animation-duration: 31s;
  animation-delay: -192s;
  transform-origin: -15vw -14vh;
  box-shadow: 2vmin 0 0.9728657743563479vmin currentColor;
}
.background span:nth-child(11) {
  color: #ee5134;
  top: 56%;
  left: 18%;
  animation-duration: 37s;
  animation-delay: -145s;
  transform-origin: 4vw -10vh;
  box-shadow: 2vmin 0 0.7048585146655255vmin currentColor;
}
.background span:nth-child(12) {
  color: #ee5134;
  top: 45%;
  left: 92%;
  animation-duration: 169s;
  animation-delay: -59s;
  transform-origin: 6vw -11vh;
  box-shadow: 2vmin 0 0.9337039079790527vmin currentColor;
}
.background span:nth-child(13) {
  color: #d1d1d1;
  top: 77%;
  left: 33%;
  animation-duration: 167s;
  animation-delay: -197s;
  transform-origin: 21vw 14vh;
  box-shadow: 2vmin 0 0.9997514240012793vmin currentColor;
}
.background span:nth-child(14) {
  color: #ee5134;
  top: 72%;
  left: 3%;
  animation-duration: 94s;
  animation-delay: -196s;
  transform-origin: -21vw -23vh;
  box-shadow: 2vmin 0 0.47864412633380193vmin currentColor;
}
.background span:nth-child(15) {
  color: #ee5134;
  top: 81%;
  left: 63%;
  animation-duration: 81s;
  animation-delay: -163s;
  transform-origin: -14vw 24vh;
  box-shadow: -2vmin 0 0.9528545292828986vmin currentColor;
}
.background span:nth-child(16) {
  color: #ee5134;
  top: 70%;
  left: 75%;
  animation-duration: 98s;
  animation-delay: -138s;
  transform-origin: -20vw -1vh;
  box-shadow: 2vmin 0 0.5433869358598171vmin currentColor;
}
.background span:nth-child(17) {
  color: #ee5134;
  top: 74%;
  left: 30%;
  animation-duration: 27s;
  animation-delay: -142s;
  transform-origin: -14vw 25vh;
  box-shadow: -2vmin 0 0.7455866077530671vmin currentColor;
}
.background span:nth-child(18) {
  color: #d1d1d1;
  top: 26%;
  left: 25%;
  animation-duration: 37s;
  animation-delay: -69s;
  transform-origin: 1vw -10vh;
  box-shadow: 2vmin 0 1.2009216126336648vmin currentColor;
}
.background span:nth-child(19) {
  color: #ee5134;
  top: 74%;
  left: 49%;
  animation-duration: 160s;
  animation-delay: -107s;
  transform-origin: -21vw -15vh;
  box-shadow: 2vmin 0 0.5403429494204092vmin currentColor;
}
